import styled, { css } from 'styled-components';
import { Icon, Radio } from '@ovotech/element';

import { RepairIssue } from '@entities/enums';
import { mqLargeUp, mqMediumUp } from '@utils/mediaQuery';

const IssueOptionContainer = styled.label(
  ({ defaultChecked, theme }) => css`
    position: relative;
    border-radius: 3px;
    color: ${defaultChecked ? theme.core.color.blue.dark : theme.core.color.brand.black};
    background: ${defaultChecked
      ? theme.core.color.blue.lightest
      : theme.core.color.neutral.lightest};
    border: 1px solid
      ${defaultChecked ? theme.core.color.blue.base : theme.semantic.border.differentiated};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 0px 12px 8px;
    cursor: pointer;
    flex: 45%;
    aspect-ratio: 1 / 1;

    ${mqLargeUp(`
      flex: 20%;
    `)}
  `
);

const IssueIconLabelWrapper = styled.div(
  ({ defaultChecked, theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.core.space[4]};

    svg {
      color: ${defaultChecked ? theme.core.color.blue.dark : theme.core.color.green.light};
    }
  `
);

const IssueOptionLabel = styled.div`
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  margin-top: 4px;

  ${mqMediumUp(`
    font-size: 18px;  
  `)}
`;

const RadioWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

interface IssueOptionProps {
  iconName: string;
  id: string;
  repairIssue: RepairIssue;
  defaultChecked: boolean;
}

export const IssueOption = ({ id, repairIssue, iconName, defaultChecked }: IssueOptionProps) => {
  return (
    <IssueOptionContainer defaultChecked={defaultChecked} htmlFor={id} data-testid={repairIssue}>
      <RadioWrapper>
        <Radio
          label={''}
          id={id}
          name={'repairIssue'}
          key={repairIssue}
          value={repairIssue}
          defaultChecked={defaultChecked}
        />
      </RadioWrapper>
      <IssueIconLabelWrapper defaultChecked={defaultChecked}>
        <Icon name={iconName} size={24} />
        <IssueOptionLabel>{repairIssue}</IssueOptionLabel>
      </IssueIconLabelWrapper>
    </IssueOptionContainer>
  );
};
