import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Radio, RadioField } from '@ovotech/element';

const Wrapper = styled.div`
  background-color: #ecf0fd;
  padding: 12px;
  width: 100%;
  border-radius: ${({ theme }) => theme.core.radius.medium};
`;

const Question = styled.div`
  color: ${({ theme }) => theme.core.color.brand.black};
`;

interface OptionProps {
  label?: string;
  hint?: ReactNode;
}

interface YesNoQuestionProps {
  question: string;
  id: string;
  name: string;
  defaultValue: 'yes' | 'no';
  yes?: OptionProps;
  no?: OptionProps;
}

export const YesNoQuestion: FC<YesNoQuestionProps> = ({
  question,
  id,
  name,
  defaultValue,
  yes,
  no,
}) => {
  return (
    <Wrapper data-testid={'yes-no-question'}>
      <Question>{question}</Question>
      <RadioField label={''} name={name} id={id} defaultValue={defaultValue}>
        <Radio id={`${name}-yes`} label={'Yes'} value={'yes'} {...yes} />
        <Radio id={`${name}-no`} label={'No'} value={'no'} {...no} />
      </RadioField>
    </Wrapper>
  );
};
