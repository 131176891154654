import { IconWithContent } from '@components/IconWithContent';
import { RepairIssue } from '@entities/enums';

interface AccordionContentProps {
  repairIssue: RepairIssue;
}

export const AccordionContent = ({ repairIssue }: AccordionContentProps) => {
  switch (repairIssue) {
    case RepairIssue.BOILER_AND_CENTRAL_HEATING:
      return (
        <>
          <IconWithContent>Boilers beyond economical repair</IconWithContent>
          <IconWithContent>
            Cylinders, extended flue systems, and steel or iron pipes
          </IconWithContent>
          <IconWithContent>System design and installation faults</IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.INTERNAL_DRAINS_AND_WASTE_PIPES:
      return (
        <>
          <IconWithContent>
            Faults caused by items unsuitable for disposal in drains, public drains and sewers
          </IconWithContent>
          <IconWithContent>Steel or iron pipes</IconWithContent>
          <IconWithContent>System design and installation faults</IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.PLUMBING:
      return (
        <>
          <IconWithContent>
            Ceramic discs in taps or replace taps, faucets or shower hardware
          </IconWithContent>
          <IconWithContent>Leaking toilet pans</IconWithContent>
          <IconWithContent>Steel or iron pipes</IconWithContent>
          <IconWithContent>System design and installation faults</IconWithContent>
          <IconWithContent>
            Damage caused by corrosion, sludge, scale, or other debris
          </IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
    case RepairIssue.ELECTRICS:
      return (
        <>
          <IconWithContent>If a full system rewire is required</IconWithContent>
          <IconWithContent>Wiring that’s sheathed or cased in fabric</IconWithContent>
          <IconWithContent>
            Fluorescent light starters, decorative or low voltage lighting
          </IconWithContent>
          <IconWithContent>System design and installation faults</IconWithContent>
          <IconWithContent>Accidental damage</IconWithContent>
        </>
      );
  }
};
