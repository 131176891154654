import { Card, Col, Margin, P, Stack } from '@ovotech/element';
import styled from 'styled-components';

import { BlackHeading4 } from '@src/Styles/Common.Styles';
import { useAuth } from '@hooks/useAuth';
import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents, Page } from '@entities/enums';
import StoreService from '@services/StoreService';
import { useCustomMixpanel } from '@services/Mixpanel';
import { PrimaryCTA } from '@components/PrimaryCTA';

const CenterTextCard = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.core.space[4]};
  padding-bottom: ${({ theme }) => theme.core.space[0]};
`;

const LoginCard = () => {
  const { user, loading, error } = useAuth();
  const { isHomeRecover } = StoreService.load();
  const { navigateToLogin } = useAppContext();
  const { track } = useCustomMixpanel();

  const redirectToLogin = () => {
    sessionStorage.setItem('lastElement', 'login-cta');
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'login',
    });
    navigateToLogin(isHomeRecover ? Page.REPAIR_DETAILS : Page.DETAILS);
  };
  if ((loading || user) && !error) return null;
  return (
    <CenterTextCard>
      <Col small={12} medium={8}>
        <BlackHeading4>Already an OVO Customer?</BlackHeading4>
        <Margin top={1} />
        <Stack spaceBetween={6}>
          <P>If you&apos;re an existing OVO customer, login here</P>
          <PrimaryCTA iconRight={'chevron-right'} fullWidth={true} onClick={redirectToLogin}>
            Log in
          </PrimaryCTA>
        </Stack>
      </Col>
    </CenterTextCard>
  );
};

export default LoginCard;
