import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import styled from 'styled-components';

import { YesNoQuestion } from '@components/YesNoQuestion';
import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { Repair } from '@services/RepairService';

const ErrorHintMessage = styled.div`
  color: ${({ theme }) => theme.core.color.brand.black};
  font-size: 14px;
  line-height: 21px;
  padding: 12px 0;
`;

const YesNoQuestionWrapper = styled.div`
  margin-left: -4rem;
  margin-top: 2rem;
`;

interface ErrorHintProps {
  title?: string;
  error: string | null;
  showYesNo?: boolean;
}

export const ErrorHint = ({ title = '', error, showYesNo = false }: ErrorHintProps) => {
  const { values } = useFormikContext<Repair>();
  const { track } = useCustomMixpanel();

  useEffect(() => {
    if (error && !showYesNo) {
      track(MixpanelEvents.ERROR, {
        error,
      });
    }
  }, [error, showYesNo, track]);

  if (!error) {
    return null;
  }

  if (!showYesNo) {
    return <ErrorHintMessage data-testid={'error-hint'}>{error}</ErrorHintMessage>;
  }

  return (
    <YesNoQuestionWrapper>
      <YesNoQuestion
        question={title}
        id={'confirm'}
        name={'confirm'}
        defaultValue={values.confirm || 'yes'}
        no={{
          hint: values.confirm === 'no' ? <ErrorHint error={error} /> : null,
        }}
      />
    </YesNoQuestionWrapper>
  );
};
