import { navigate } from 'gatsby';
import { useCallback, useEffect } from 'react';
import { WindowLocation } from '@reach/router';

import { RepairDetails } from './RepairDetails';

import { ROUTE_HOME_RECOVER, ROUTE_HOME_RECOVER_DETAILS } from '@constants/routes';
import { CoverType, MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import repairService, { Repair } from '@services/RepairService';
import StoreService from '@services/StoreService';
import useQuote from '@hooks/useQuote';
import { useAppContext } from '@contexts/AppContext';

interface Props {
  location: WindowLocation;
}

export const RepairDetailsPage = ({ location }: Props) => {
  const { boilerDetails } = StoreService.load();
  const { track } = useCustomMixpanel();

  const { excess } = useAppContext();

  const { quotes } = useQuote({
    excess,
    boilerAge: parseInt(boilerDetails.boilerAge),
  });

  const quote = quotes.find((quote) => quote.coverType === CoverType.COMPLETE);

  useEffect(() => {
    if (!quote) {
      navigate(ROUTE_HOME_RECOVER, {
        replace: true,
      });
    }
  }, [quote]);

  useEffect(() => {
    track(MixpanelEvents.PAGE_REPAIR_DETAILS, { pageName: null });
  }, [track]);

  const onSubmit = useCallback((values: Repair) => {
    repairService.save(values);

    navigate(ROUTE_HOME_RECOVER_DETAILS);
  }, []);

  const onClickBack = useCallback(() => {
    navigate(ROUTE_HOME_RECOVER);
  }, []);

  return !quote ? null : (
    <RepairDetails
      quote={quote}
      initialValues={repairService.get()}
      location={location}
      onSubmit={onSubmit}
      onClickBack={onClickBack}
    />
  );
};
